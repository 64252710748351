// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

  apiKey: "AIzaSyCMa3Q1kGOWKvzwu1t9VDJVboqytiNJpDA",

  authDomain: "asola-344a7.firebaseapp.com",

  databaseURL: "https://asola-344a7-default-rtdb.firebaseio.com",

  projectId: "asola-344a7",

  storageBucket: "asola-344a7.appspot.com",

  messagingSenderId: "423867229757",

  appId: "1:423867229757:web:e27a99a9fb1893944eac54",

  measurementId: "G-G337D0VT49"

};




// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };